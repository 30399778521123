import React, { useState } from 'react'
import Socials from '../components/Socials'
//import yanely from '../../images/yanely_ayala.png'
import icon from '../images/icon.png'

import styled from 'styled-components'

const useImageLoader = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const onLoad = () => {
    setIsLoaded(true);
  };

  return { isLoaded, onLoad };
};

const Home = () => {
  const { isLoaded, onLoad } = useImageLoader();

  return (
    <>
      <Container>
        <div className='main-content'>
          <div className='icon'>
            <img 
              src={icon} 
              alt="icon"
              className={isLoaded ? "loaded" : ""}
              onLoad={onLoad}
              />
          </div>
        </div>
        <div className='footer'>
          <Socials />
        </div>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  img {
    opacity: 0; /* Start transparent */
    transform: translateY(20px); 
    transition: opacity 3s ease, transform 2s ease; 
  }

  img.loaded {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 1250px) {
    img {
      padding: 50px;
      max-width: 100%; 
    } 
  }
`;

export default Home;