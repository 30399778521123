import React from 'react'

import headshot from '../images/headshot.jpg'
import grad1 from '../images/grad1.jpg'

import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

import styled from 'styled-components'

const CarouselSlider = () => {
  return (
    <div className='image-carousel'>
        <StyledCarousel 
            infiniteLoop={true} 
            autoPlay={true}
            showStatus={false}
            showThumbs={false}
            showArrows={false}
        >
        <div>
            <img src={grad1} alt='graduation' />
        </div>
        <div>
            <img src={headshot} alt='headshot' />
        </div>
        </StyledCarousel> 
    </div>
  )
}

const StyledCarousel = styled(Carousel)`
    .carousel-slider {
        border-radius: 50%;
    }
    img {
        border-radius: 5%;
    }
    .thumb.selected{
        border-radius: 5%;
    }
`;

export default CarouselSlider