import React from 'react'

import styled from 'styled-components'

import { Link } from 'react-router-dom'
 
const Entry1 = () => {
  return (
    <Container>
      
      hi. thanks for stopping by (:. 
      <br></br>

      <Container>
        <Link to={'/about'}>back to blogs.</Link>
      </Container>
      
    </Container>
  )
}

const Container = styled.div`
  padding: 50px;
`;

export default Entry1