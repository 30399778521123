import React, { useState, useRef } from 'react'

import { Form, Button, TextArea, Input } from 'semantic-ui-react'

import emailjs from 'emailjs-com'
import Swal from 'sweetalert2'

import { SERVICE_ID, TEMPLATE_ID, PUBLIC_KEY } from '../Constants.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';

import styled from 'styled-components'

export const ContactForm = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const form = useRef();

    const handleSubmit = (e) => {
      e.preventDefault();
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
        .then(
          (result) => {
          console.log(result.text);
          Swal.fire({
            icon: 'success',
            title: 'Message Sent Successfully'
          })
        }, 
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: 'error',
            title: 'Ooops, something went wrong',
            text: error.text,
          })
        });
      e.target.reset()

      setName('')
      setEmail('')
      setMessage('')
    };
  
  return (
    <Container>
      <Form ref={form} onSubmit={handleSubmit}>
        <Form.Field
        control={Input}
        label={
        <> 
            <label className="bold-label">
              <FontAwesomeIcon icon={faUser} /> Name: 
            </label>
        </>
        }
        value={name}
        name='name'
        required
        
        onChange={(e) => setName(e.target.value)} >
        </Form.Field>

        <Form.Field
          control={Input}
          label={
          <> 
            <label className="bold-label">
              <FontAwesomeIcon icon={faEnvelope} /> Email: 
            </label>
          </>
          }
          value={email}
          name='email'
          required
          onChange={(e) => setEmail(e.target.value)} >
        </Form.Field>

        <Form.Field
          control={TextArea}
          label='Message'
          name='message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required>
        </Form.Field>
        
          <Button type='submit' color='green'>Submit</Button>
      </Form>
    </Container>
  )
}

const Container = styled.div`
  @media (max-width: 1250px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
  }
`;
