import React from 'react'
import Socials from '../components/Socials'
import CarouselSlider from '../components/CarouselSlider'

import styled from 'styled-components'

import { Link } from 'react-router-dom'


const About = () => {
  return (
    <div>
      <h1 className='subtitle'>about me.</h1>

      <CarouselSlider />

      <Subtitle>blogs.</Subtitle> 
      <Container>
        <StyledLink to='/readme'>readme.md</StyledLink>
      </Container>

      
      <div className='footer'>
        <Socials />
      </div>
    </div>
  )
}

const Subtitle = styled.h2`
  display: flex;
  margin-left: 10px;
`;

const Container = styled.div`
  display: flex;
  padding: 0px 0px 50px 30px;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

export default About